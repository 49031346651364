import axios from "axios"
import appConfig from "@/helpers/appConfig.js"
import realtimeEvents from "@/events/index"
import { storeModules } from "./modules/index"
import { createStore } from "vuex"

//Set Base API URL
axios.defaults.baseURL = appConfig.api + "v5"
axios.defaults.withCredentials = appConfig.axios().withCredentials

const debug = process.env.NODE_ENV !== "production"

const store = createStore({
  modules: storeModules,
  strict: debug,
  plugins: [realtimeEvents]
})

export default store
