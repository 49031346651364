import axios from "axios"
import moment from "moment-timezone"
import { configure, configure7, apiV7nocache } from "./axiousWithCache"
import tokenService from "@/helpers/tokenService.js"
import cookieService from "@/helpers/cookies"
import localforage from "localforage"
import store from "@/store/index"

const state = {
  user: null,
  attempts: null,
  isUserInTransparency: false,
  isUserUnavailable: false,
  identifiedInGainsight: false
}

const getters = {
  user(state) {
    return state.user
  },
  formListUser(state) {
    return [
      {
        value: {
          id: state.user.id,
          type: "App\\Models\\User",
          passesToday: state.user.passes_for_today_count
            ? parseInt(state.user.passes_for_today_count)
            : 0
        },
        label: state.user.first_name + " " + state.user.last_name
      }
    ]
  },
  getUserRole(state) {
    if (state.user && state.user.role_id) {
      switch (state.user.role_id) {
        case 1:
          return "student"
        case 2:
          return "admin"
        case 3:
          return "teacher"
        case 4:
          return "staff"
        case 5:
          return "superadmin"
        default:
          return null
      }
    }
  },
  isStudent(state) {
    return state.user.role_id === 1
  },
  isAuthenticated(state) {
    return !!state.user
  },
  isUserAvailable(state) {
    return state.user && state.user.is_available
  },
  isMuted(state) {
    return !state.user.audio_enabled
  },
  attempts(state) {
    return state.attempts
  },
  pinAttemptsCount(state) {
    return state.user && state.user.pin_attempts_count
  },
  isUserInTransparency(state) {
    return state.isUserInTransparency
  },
  isUserUnavailable(state) {
    return state.isUserUnavailable
  }
}

const mutations = {
  SET_USER(state, userData) {
    state.user = { ...state.user, ...userData }
  },
  TOGGLE_USER_AUDIO(state, audioEnabled) {
    state.user.audio_enabled = audioEnabled
  },
  UPDATE_USER(state, userData) {
    state.user = userData ? Object.assign(state.user, userData) : state.user
  },
  SET_USER_UNAVAILABLE(state, status) {
    state.user.is_available = status
  },
  SET_ATTEMPTS(state, attempts) {
    state.attempts = attempts
  },
  SET_USER_IS_LOCKED(state, data) {
    state.user.is_locked = data
  },
  RESET_PIN_ATTEMPTS_COUNT(state) {
    state.user.pin_attempts_count = 0
  },
  SET_USER_TRANSPARENCY(state, transparencyStatus) {
    state.isUserInTransparency = transparencyStatus
  },
  SET_IS_USER_UNAVAILABLE(state, status) {
    state.isUserUnavailable = status
  },
  SET_GAINSIGHT_IDENTIFIED(state, status) {
    state.identifiedInGainsight = status
  }
}

const actions = {
  csrfAuthentication() {
    return new Promise((resolve) => {
      axios({
        url: "/authentication/csrf-cookie",
        baseURL: process.env.VUE_APP_AUTH_URL
      }).then((response) => {
        resolve(response)
      })
    })
  },
  async login(context, user) {
    if (process.env.NODE_ENV === "production") {
      await context.dispatch("csrfAuthentication")
    }
    return new Promise((resolve, reject) => {
      const instanceLogin = axios.create({
        baseURL:
          process.env.NODE_ENV === "production"
            ? process.env.VUE_APP_AUTH_URL + "/api/v5"
            : process.env.VUE_APP_BASE_URL + "/api/v5"
      })
      instanceLogin
        .post("/users/login", user)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error.response.data)
        })
    })
  },
  async resetPassword(context, user) {
    await context.dispatch("csrfAuthentication")
    return new Promise((resolve, reject) => {
      axios
        .post("/users/password/reset", user)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error.response.data)
        })
    })
  },
  forceResetPassword(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/users/password/reset/force", data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error.response.data)
        })
    })
  },
  getUserDetails(context, config) {
    return new Promise((resolve, reject) => {
      configure7()
        .then(async (api) => {
          const response = await api.get("/users/me", config)
          const user = response.data.data
          if (user && user.created_at) {
            context.commit("SET_USER", user)
            if (!state.identifiedInGainsight) {
              let currentUserRole = null
              if (user && state.user && state.user.role_id) {
                currentUserRole = store.getters["authentication/getUserRole"]
                window.aptrinsic(
                  "identify",
                  {
                    id: user.email,
                    email: user.email,
                    firstName: user.first_name,
                    lastName: user.last_name,
                    rolePass: currentUserRole
                  },
                  {
                    id: user.current_role_in_school.pivot.school_id,
                    name: user.current_role_in_school.pivot.school_name
                  }
                )
                context.commit("SET_GAINSIGHT_IDENTIFIED", true)
              }
            }
          }
          if (
            Object.prototype.hasOwnProperty.call(
              response.data,
              "transparency_status"
            )
          ) {
            context.commit(
              "SET_USER_TRANSPARENCY",
              response.data.transparency_status
            )
          }
          resolve(user)
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            context.dispatch("unauthorize")
            window.location.href = "/login"
            reject(error)
          }
          reject(error)
        })
    })
  },
  getUserSettings(context, config) {
    return new Promise((resolve, reject) => {
      configure()
        .then(async (api) => {
          const response = await api.get("/users/me/settings", config)
          const user = response.data.data

          if (user) {
            context.commit("SET_USER", user)
          }
          resolve()
        })
        .catch((error) => {
          if (error.response.status === 401) {
            context.dispatch("unauthorize")
            window.location.href = "/login"
            reject(error)
          }
          reject(error)
        })
    })
  },
  unlock(context, pin) {
    return new Promise((resolve, reject) => {
      axios
        .post("/users/unlock", { teacher_pin: pin })
        .then(() => {
          context
            .dispatch("getUserDetails", { clearCacheEntry: true })
            .then(() => {
              window.vueRouter.push("/")
              resolve()
            })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getAudioPreferences() {
    let endpoint = ""
    //if user is student
    if (state.user && state.user.role_id == 1) {
      endpoint = "/users/me/audio"
    } else {
      endpoint = "/admin/users/profile/audio"
    }
    return new Promise((resolve, reject) => {
      axios
        .get(endpoint)
        .then((response) => {
          const data = response.data.data
          if (data) {
            state.user.audio_enabled = data.audio_enabled
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getAttempts(context) {
    return new Promise((resolve, reject) => {
      axios
        .get("/users/unlock")
        .then((response) => {
          context.commit("SET_ATTEMPTS", response.data.data)
          context.dispatch("getUserDetails", { clearCacheEntry: true })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getUserInitialUnavailability(context) {
    return new Promise((resolve, reject) => {
      axios
        .get("/admin/unavailables/active")
        .then((response) => {
          const unavailable = response.data.data.active_unavailable
          const unavailableStatus =
            Array.isArray(unavailable) && unavailable.length ? true : false

          context.commit("SET_USER_UNAVAILABLE", unavailableStatus)
          context.commit("SET_IS_USER_UNAVAILABLE", unavailableStatus)
          if (!unavailableStatus) {
            context.commit("dashboardTable/SET_UNAVAILABLE", null, {
              root: true
            })
          } else {
            context.commit("dashboardTable/SET_UNAVAILABLE", unavailable, {
              root: true
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateKioskPass(context, passcode) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/users/me`, { kiosk_passcode: passcode })
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  logOut(context) {
    axios
      .post(`/users/logout`, {
        RealtimeEventsAuth: tokenService.getToken()
      })
      .then(() => {
        context.dispatch("unauthorize")
        if (
          window.location.pathname != "/kiosk/login" &&
          window.location.pathname != "/kiosk/launch" &&
          window.location.pathname != "/login"
        ) {
          window.location.replace("/login")
        }
      })
  },
  unauthorizeIfUserIsLogged({ getters, dispatch }) {
    if (getters.isAuthenticated) {
      axios.post(`/users/logout`).then(() => {
        dispatch("unauthorize")
        if (
          window.location.pathname != "/kiosk/login" &&
          window.location.pathname != "/kiosk/launch" &&
          window.location.pathname != "/login"
        ) {
          window.location.replace("/login")
        }
      })
    }
  },
  unauthorizeArchivedUser(context) {
    context.dispatch("unauthorize")
    window.location.replace("/login")
  },
  saveToken(context, token) {
    tokenService.saveToken(token)
    localStorage.setItem("logoutTime", moment().endOf("day").toISOString())
    localStorage.setItem("sessionEnd", moment().add(12, "hours"))
  },
  unauthorize() {
    localStorage.removeItem("accessToken")
    localStorage.clear()
    localforage.clear()
    cookieService.removeAllCookies()
  },
  async externalLogin(context, data) {
    const headers = {}
    if (data.isMobile) {
      headers["From-Application"] = "Mobile"
    }
    await context.dispatch("csrfAuthentication")
    return new Promise((resolve, reject) => {
      const instanceLogin = axios.create({
        baseURL: process.env.VUE_APP_AUTH_URL + "/api/v5"
      })
      instanceLogin
        .post(`/login/oauth`, data, {
          headers
        })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error.response.data)
        })
    })
  },
  updateUserProfile(context, user) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/admin/users/profile`, user)
        .then((response) => {
          context.commit("UPDATE_USER", user)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  deleteUserAvatar() {
    return new Promise((resolve, reject) => {
      axios
        .post(`/admin/users/delete/avatar`)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  verifyEmailAddress(context, verificationCode) {
    return new Promise((resolve, reject) => {
      apiV7nocache
        .post(`/sso/verifyEmail`, verificationCode)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
